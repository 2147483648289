<template>
  <div style="min-height: 100vh">
    <div v-if="docsLoaded" class="fill-height">
      <order-status-viewer v-if="$route.name === 'Order Statuses'" />
      <div v-else class="fill-height">
        <v-app-bar app clipped-left :dark="true" style="z-index: 1000">
          <v-app-bar-nav-icon
            v-if="currentUserRole !== 'Rider'"
            class="d-lg-none mr-2"
            @click="drawer = !drawer"
          />
          <img class="mr-4" :src="getLogoImg()" :style="getLogoStyle()" />
          <v-app-bar-title class="text-no-wrap pl-0 d-none d-sm-block">
            <h1 class="text-h6 pl-3" style="border-left: solid 1px">
              Restaurant Manager
              <v-chip
                v-if="
                  firebaseRefs.organization.env &&
                  firebaseRefs.organization.env !== 'prod'
                "
                color="warning"
                text-color="black"
                class="text-uppercase ml-1"
              >
                {{ firebaseRefs.organization.env }}
              </v-chip>
              <v-chip
                v-else-if="isLocalhost"
                color="success"
                class="text-uppercase ml-1"
              >
                LIVE
              </v-chip>
            </h1>
          </v-app-bar-title>
          <v-spacer />
          <v-btn
            v-if="currentUserRole !== 'Rider'"
            to="/settings/settings"
            class="mr-3"
            fab
            rounded
            small
            color="text-on-primary-bg"
          >
            <v-icon color="primary">mdi-cog</v-icon>
          </v-btn>
          <v-menu offset-y style="z-index: 200">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                rounded
                small
                v-bind="attrs"
                color="text-on-primary-bg"
                v-on="on"
              >
                <v-icon color="primary">mdi-account</v-icon>
              </v-btn>
            </template>
            <v-card
              tile
              class="mx-auto mt-2"
              :width="Math.min(Math.max(userOrgDisplayText.length * 10, 200), 300)"
            >
              <v-list nav dense>
                <v-subheader>
                  <span class="add-ellipsis">
                    {{ userOrgDisplayText }}
                  </span>
                </v-subheader>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>DARK THEME</v-list-item-title>
                  <v-switch
                    v-model="$vuetify.theme.dark"
                    :color="$vuetify.theme.dark ? `success` : `light`"
                    class="align-self-start"
                  ></v-switch>
                </v-list-item>
                <v-list-item
                  v-if="currentUserEmail?.includes('nextgenkitchens.com')"
                  class="mt-n6"
                >
                  <v-list-item-title>SHOW DELETED</v-list-item-title>
                  <v-switch
                    v-model="showDeleted"
                    :color="showDeleted ? `success` : `light`"
                    class="align-self-start"
                  ></v-switch>
                </v-list-item>
                <v-list-item class="mt-n6">
                  <v-list-item-title>SOUND ON</v-list-item-title>
                  <v-switch
                    v-model="soundOn"
                    :color="soundOn ? `success` : `light`"
                    class="align-self-start"
                  ></v-switch>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    @click="
                      $router.push({
                        name: 'Logout',
                        query: { redirect: $route.path },
                      })
                    "
                  >
                    <span class="d-none d-md-flex">Sign Out</span>
                    <v-icon right>mdi-location-exit</v-icon>
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-app-bar>
        <div v-show="loaded">
          <side-nav v-if="currentUserRole !== 'Rider'" :drawer="drawer" />
          <v-main class="fill-height">
            <v-alert v-if="!isOnline" type="warning" class="ma-4">
              You are offline. Please connect to the internet.
            </v-alert>
            <v-container
              class="px-4 py-0 pt-4 fill-height align-start main-container"
              fluid
            >
              <v-row>
                <v-col>
                  <transition name="fade">
                    <router-view></router-view>
                  </transition>
                </v-col>
              </v-row>
            </v-container>
            <v-footer class="text--disabled" color="light-background" bottom padless>
              <v-col cols="12" md="3">
                <small>
                  &copy; {{ new Date().getFullYear() }}
                  <a
                    href="https://nextgenkitchens.com/"
                    target="_blank"
                    style="color: inherit; text-decoration: none"
                  >
                    NextGen Kitchens
                  </a>
                </small>
              </v-col>
              <v-col class="text-center" cols="12" md="6">
                <printer-troubleshoot-button
                  v-if="
                    $route.name === 'Sales' &&
                    $route.params &&
                    $route.params.tab === 'orders'
                  "
                />
              </v-col>
              <v-col class="text-right" cols="12" md="3">
                <small>
                  <a
                    href="https://nextgenkitchens.com/terms-of-use/"
                    target="_blank"
                    style="color: inherit; text-decoration: none"
                  >
                    Terms of Use
                  </a>
                </small>
                <small>&nbsp;|&nbsp;</small>
                <small>
                  <a
                    href="https://nextgenkitchens.com/app-privacy-policy/"
                    target="_blank"
                    style="color: inherit; text-decoration: none"
                  >
                    Privacy Policy
                  </a>
                </small>
              </v-col>
            </v-footer>
          </v-main>
        </div>
        <loading-page v-if="!loaded">Loading...</loading-page>
      </div>
    </div>
    <empty
      v-else-if="!isOnline"
      title="No internet connection"
      :img="getLogoImg()"
      img-contain
    >
      You are offline. Please connect to the internet.
    </empty>
    <loading-page v-else>{{ loadText }}</loading-page>
    <notification-bar />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex"
import { getAuth } from "firebase/auth"
import SideNav from "./SideNav"
import OrderStatusViewer from "./OrderStatusViewer"
import NotificationBar from "./NotificationBar"
import PrinterTroubleshootButton from "./shared/PrinterTroubleshootButton.vue"
import Empty from "../Empty"
import LoadingPage from "../LoadingPage"

export default {
  name: "authenticated-content",
  components: {
    SideNav,
    OrderStatusViewer,
    PrinterTroubleshootButton,
    Empty,
    LoadingPage,
    NotificationBar,
  },
  data() {
    return {
      isOnline: true,
      drawer: true,
      showDeleted: this.$store.state.showDeleted,
      viewSettings: false,
      currentPage: this.$route.path,
      loaded: true,
    }
  },
  computed: {
    ...mapState([
      "firebaseRefs",
      "users",
      "loadText",
      "settings",
      "loadProgress",
      "currentUserRole",
      "currentUserEmail",
    ]),
    ...mapGetters(["siteTitle"]),
    userDisplayName() {
      const user = getAuth().currentUser
      if (!user) return "Anonymous"
      if (user?.displayName) return user.displayName
      else if (this.users) {
        const userDoc = Object.values(this.users)?.find(u => u.userId === user.uid)
        if (userDoc?.name) return userDoc.name
      }
      return user.email
    },
    userOrgDisplayText() {
      let text = this.userDisplayName
      if (this.settings.organizationName) {
        text += ` - ${this.settings.organizationName}`
      }
      return text
    },
    isLocalhost() {
      return (
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
      )
    },
    isNextEats() {
      return this.siteTitle === "NextEats"
    },
    docsLoaded() {
      return this.loadProgress === 1
    },
    soundOn: {
      get() {
        return this.$store.state.soundOn
      },
      set(value) {
        this.setSoundOn(value)
      },
    },
  },
  watch: {
    showDeleted(val) {
      this.$store.state.showDeleted = val
      this.loaded = false
      this.fetchAllData().then(() => {
        this.loaded = true
      })
    },
    soundOn(val) {
      this.setSoundOn(val)
    },
  },
  async mounted() {
    this.isOnline = window.navigator.onLine
    window.addEventListener("online", this.goOnline)
    window.addEventListener("offline", this.goOffline)
    this.loadData()
  },
  beforeDestroy() {
    window.removeEventListener("online", this.goOnline)
    window.removeEventListener("offline", this.goOffline)
  },
  methods: {
    ...mapActions(["fetchAllData", "fetchOrgDoc", "fetchSettings"]),
    ...mapMutations(["setSoundOn"]),
    loadData() {
      if (this.isOnline) {
        this.fetchAllData().catch(e => {
          console.error("error", e)
        })
      }
    },
    goOnline() {
      this.isOnline = true
      if (!this.docsLoaded) {
        this.loadData()
      }
    },
    goOffline() {
      this.isOnline = false
    },
    getLogoImg() {
      const logoPath = this.isNextEats ? "logos/nexteats.webp" : "logo.svg"
      return require("@/assets/" + logoPath)
    },
    getLogoStyle() {
      let style = "max-height: 30px;"
      if (this.isNextEats) {
        style += " padding: 4px 0 6px;"
      }
      return style
    },
  },
}
</script>

<style lang="scss">
@media screen and (min-width: 700px) {
  .v-app-bar-title__content {
    width: 300px !important;
  }
}
.main-container {
  min-height: calc(100vh - 160px);
}
.v-app-bar {
  background-color: var(--v-primary-base) !important;
}
.v-input--selection-controls .v-input__slot > .v-label {
  font-size: 0.8rem !important;
}
.add-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

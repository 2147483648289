<template>
  <v-container class="mt-12">
    <v-alert v-if="error" type="error" class="mx-auto" max-width="400">
      {{ error }}
    </v-alert>

    <v-card class="mx-auto" max-width="400">
      <v-card-title class="headline">Set Password Below</v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            label="New Password"
            append-icon="mdi-eye"
            outlined
            :error-messages="getFormErrors($v, 'password')"
            :disabled="loading"
            @click:append="showPassword = !showPassword"
          />

          <v-text-field
            v-model="verifyPassword"
            :type="showVerifyPassword ? 'text' : 'password'"
            label="Verify Password"
            append-icon="mdi-eye"
            outlined
            :error-messages="getFormErrors($v, 'verifyPassword')"
            :disabled="loading"
            @click:append="showVerifyPassword = !showVerifyPassword"
          />

          <v-btn
            color="primary"
            block
            :disabled="loading"
            :loading="loading"
            @click="submitForm"
          >
            Submit
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators"
import GeneralMixins from "@/AuthenticatedContent/mixins"

export default {
  mixins: [GeneralMixins],
  data() {
    return {
      password: "",
      verifyPassword: "",
      showPassword: false,
      showVerifyPassword: false,
      token: this.$route.params.token,
      error: null,
      loading: false,
    }
  },
  validations() {
    return {
      password: { required, minLength: minLength(6) },
      verifyPassword: {
        required,
        sameAsPassword: value => value === this.password || "Passwords do not match",
      },
    }
  },
  methods: {
    submitForm() {
      this.error = null
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.password !== this.verifyPassword) {
        this.error = "Passwords do not match!"
      } else {
        this.loading = true

        this.setPassword(this.password, this.token)
          .then(res => {
            if (res.data.success) {
              this.$router.push({ name: "Login", query: { pwSetSuccess: true } })
            } else {
              this.error = res.data.message
            }
          })
          .catch(err => {
            console.error(err)
            this.error = "An error occurred."
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>

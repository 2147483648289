<template>
  <v-app>
    <v-container class="mt-12">
      <div>
        <v-alert v-if="error" type="error">
          {{ error }}
        </v-alert>
        <v-alert v-else-if="successMessage" type="success">
          {{ successMessage }}
        </v-alert>
        <v-alert v-else-if="!reset" type="warning" dismissible>
          Please login to continue.
        </v-alert>
      </div>
      <v-card class="mt-12">
        <v-card-title>{{ reset ? "Reset Password" : "Login" }}</v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="
              reset ? handleSendPasswordResetEmail() : authenticateUser()
            "
            @keyup.enter.native="
              reset ? handleSendPasswordResetEmail() : authenticateUser()
            "
          >
            <v-row justify="center">
              <v-col cols="5" align-self="center">
                <v-text-field
                  v-model="email"
                  label="Email"
                  :error-messages="getFormErrors($v, 'email')"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row v-if="!reset" justify="center">
              <v-col cols="5" align-self="center">
                <v-text-field
                  v-model="password"
                  type="password"
                  label="Password"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="3" align-self="center">
                <v-btn
                  v-if="!reset"
                  color="primary"
                  :disabled="loggingIn"
                  block
                  @click.prevent="authenticateUser()"
                >
                  <v-progress-circular
                    v-if="loggingIn"
                    indeterminate
                    size="15"
                    width="2"
                    class="mr-2"
                  ></v-progress-circular>
                  Login
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  block
                  @click.prevent="handleSendPasswordResetEmail()"
                >
                  Reset
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row justify="center">
            <v-col cols="3" align-self="center">
              <v-btn
                v-if="!reset"
                color="primary"
                text
                block
                @click.prevent="
                  reset = true
                  error = successMessage = null
                "
              >
                Forgot Password?
              </v-btn>
              <v-btn
                v-else
                color="primary"
                text
                block
                @click.prevent="
                  reset = false
                  successMessage = error = null
                "
              >
                Back to Login
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  signOut,
  getAuth,
  sendPasswordResetEmail,
} from "firebase/auth"
import { auth } from "./firebaseConfig"
import { required, email } from "vuelidate/lib/validators"
import { mapState, mapMutations, mapActions } from "vuex"

export default {
  data() {
    return {
      email: null,
      password: null,
      error: null,
      successMessage: null,
      loggingIn: false,
      reset: false,
      numberOfRetries: 0,
    }
  },
  validations() {
    return {
      email: { required, email },
    }
  },
  computed: {
    ...mapState(["currentUserEmail", "firebaseRefs"]),
  },
  mounted() {
    if (!this.reset) {
      if (this.$route.query.loggedOut) {
        this.successMessage = "You have been logged out successfully."
        this.error = null
      }
      if (this.$route.query.pwResetSuccess) {
        this.successMessage =
          "Your password has been reset successfully. Please login."
        this.error = null
        this.$router.replace({ query: {} })
      }
      if (this.$route.query.pwSetSuccess) {
        this.successMessage =
          "Your password has been set successfully. Please login."
        this.error = null
        this.$router.replace({ query: {} })
      }
    }
  },
  methods: {
    ...mapMutations(["setCurrentUserEmail"]),
    ...mapActions(["fetchFirebaseRefs"]),
    authenticateUser() {
      if (this.loggingIn) return
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loggingIn = true
        setPersistence(auth, browserLocalPersistence).then(() => {
          signInWithEmailAndPassword(auth, this.email, this.password)
            .then(userInfo => {
              this.setCurrentUserEmail(userInfo.user.email)
              this.setupLogin()
            })
            .catch(error => {
              this.error = "Invalid email or password"
              this.successMessage = null
              console.error("sign in error", error)
              this.loggingIn = false
            })
        })
      }
    },
    async setupLogin() {
      await this.fetchFirebaseRefs().catch(error => {
        this.error = "Sorry, there was a problem logging you in. Please try again."
        this.successMessage = null
        console.error("fetchFirebaseRefs error", error)
        this.loggingIn = false
      })
      setTimeout(() => {
        if (Object.keys(this.firebaseRefs).length) {
          if (
            this.firebaseRefs?.organization?.id &&
            this.firebaseRefs.organization.id !== "CA-BC-Vancouver-NextEats" &&
            window.location.hostname === "admin.nexteats.ca"
          ) {
            signOut(auth).then(() => {
              this.setCurrentUserEmail(null)
              this.error = "Sorry, you do not have access to this application."
              this.successMessage = null
              this.loggingIn = false
              window.location.href = "https://restaurantmanager.nextgenkitchens.com"
            })
          } else {
            if (this.$route.query.redirect) {
              this.$router.push({ path: this.$route.query.redirect })
            } else {
              this.$router.push({ name: "Home" })
            }
          }
        } else {
          signOut(auth).then(() => {
            this.setCurrentUserEmail(null)
            if (this.numberOfRetries++ < 3) {
              this.authenticateUser()
            } else {
              this.error =
                "Sorry, there was a problem logging you in. Please try again."
              this.successMessage = null
              this.loggingIn = false
              this.numberOfRetries = 0
            }
          })
        }
      }, 500)
    },
    handleSendPasswordResetEmail() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const auth = getAuth()
        sendPasswordResetEmail(auth, this.email)
          .then(() => {
            this.successMessage =
              "Password reset email sent. Please check your inbox."
            this.error = null
          })
          .catch(error => {
            const fbError = error.code?.replace("auth/", "").replaceAll("-", " ")
            this.error =
              "We were not able to complete your request due to the following error: " +
              fbError.charAt(0).toUpperCase() +
              fbError.slice(1)
            this.successMessage = null
          })
      }
    },
  },
}
</script>

import { minimizeRanges } from "./helpers.js"

export function setLoadProgress(state, val) {
  state.loadProgress = val
}

export function setLoadText(state, val) {
  state.loadText = val
}

export function setCurrentUserEmail(state, val) {
  state.currentUserEmail = val
}

export function setCurrentUserRole(state, val) {
  state.currentUserRole = val
}

export function setOnboardings(state, val) {
  state.onboardings = val
}

export function setCurrentOnboarding(state, val) {
  state.onboardings[state.currentOnboardingIndex] = val
}

export function setCurrentOnboardingStepField(state, payload) {
  state.onboardings[state.currentOnboardingIndex].steps[payload.step][
    payload.field
  ] = payload.value
}

export function setCurrentOnboardingIndex(state, val) {
  if (val < state.onboardings.length) {
    state.currentOnboardingIndex = val
  }
}

export function setCurrentUserAccess(state, val) {
  state.currentUserAccess = val
}

export function setRestaurantSyncStatus(state, val) {
  state.restaurantSyncStatus = val
}

export function setFirebaseRefs(state, payload) {
  state.firebaseRefs = payload
}

export function setFirebaseGetters(state, payload) {
  state.firebaseGetters = payload
}

export function setFirebaseSnapshotUnsubscribeObjects(state, payload) {
  state.firebaseSnapshotUnsubscribeObjects = payload
}

export function setItemToEdit(state, val) {
  state.itemToEdit = val
}

export function setItemToReport(state, val) {
  state.itemToReport = val
}

export function setProfitMargin(state, val) {
  state.profitMargin = val / 100
}

export function setRestaurants(state, val) {
  state.restaurants = val
}

export function setMenus(state, payload) {
  state.menus = payload
}

export function setMenuItems(state, payload) {
  state.menuItems = payload
}

export function setMenuItemCategories(state, payload) {
  state.menuItemCategories = payload
}

export function setCategories(state, payload) {
  state.categories = payload != undefined ? payload : []
}

export function addCategory(state, payload) {
  state.categories.push(payload)
}

export function setSubcategories(state, payload) {
  state.subcategories = payload != undefined ? payload : []
}

export function addSubcategory(state, payload) {
  state.subcategories.push(payload)
}

export function setStorages(state, payload) {
  let storages = payload
  if (state.currentUserAccess?.restaurantIds?.length) {
    storages = payload.filter(storage => {
      return (
        state.currentUserAccess.restaurantIds.filter(accessRestaurant =>
          storage.restaurantIds.includes(accessRestaurant)
        ).length > 0
      )
    })
  }
  state.storages = storages.sort(function (a, b) {
    var textA = a.name?.toUpperCase()
    var textB = b.name?.toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })
}

export function addStorage(state, payload) {
  let storages = [...state.storages]
  storages.forEach(function (storage, i) {
    if (storage.id === payload.id) {
      storages[i] = payload
    } else {
      storages.push(payload)
    }
  })
  state.storages = [...new Set(storages)]
}

export function addThirdPartyType(state, thirdPartyType) {
  const index = state.thirdPartyTypes.findIndex(tp => tp.id === thirdPartyType.id)

  if (index !== -1) {
    state.thirdPartyTypes.splice(index, 1, thirdPartyType)
  } else {
    state.thirdPartyTypes.push(thirdPartyType)
  }
}

export function setThirdPartyTypes(state, thirdPartyTypes) {
  state.thirdPartyTypes = thirdPartyTypes
}

export function setOrderGuides(state, payload) {
  let orderGuides = payload
  if (state.currentUserAccess?.restaurantIds?.length) {
    orderGuides = payload.filter(orderGuide => {
      return (
        state.currentUserAccess.restaurantIds.filter(accessRestaurant =>
          orderGuide.restaurantIds?.includes(accessRestaurant)
        ).length > 0
      )
    })
  }
  state.orderGuides = orderGuides.sort(function (a, b) {
    var textA = a.name?.toUpperCase()
    var textB = b.name?.toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })
}

export function addOrderGuide(state, payload) {
  let orderGuides = [...state.orderGuides]
  orderGuides.forEach(function (orderGuide, i) {
    if (orderGuide.id === payload.id) {
      orderGuides[i] = payload
    } else {
      orderGuides.push(payload)
    }
  })
  state.orderGuides = [...new Set(orderGuides)]
}

export function setInventoryItems(state, payload) {
  state.items = payload
}

export function setInventoryFetched(state, payload) {
  state.inventoryFetched = payload
}

export function setSupplierItems(state, payload) {
  state.supplierItems = payload
}

export function setCounts(state, payload) {
  state.counts = payload
}

export function setWriteoffs(state, payload) {
  state.writeoffs = payload
}

export function setReceivings(state, payload) {
  state.receivings = payload
}

export function addSupplierItem(state, payload) {
  let supplierItems = [...state.supplierItems]
  supplierItems.forEach(function (supplierItem, i) {
    if (supplierItem.id === payload.id) {
      supplierItems[i] = payload
    } else {
      supplierItems.push(payload)
    }
  })
  state.supplierItems = [...new Set(supplierItems)]
}

export function setDiscountedOrders(state, payload) {
  state.discountedOrders = payload
}

export function setCustomerOrders(state, payload) {
  state.customerOrders = payload
}

export function setAllOpenOrders(state, payload) {
  state.allOpenOrders = payload
}

export function setAllOrders(state, payload) {
  state.allOrders = payload
  state.allOrdersLastFetched = new Date()
}

export function setAllOrdersDateRanges(state, dateRange) {
  if (!dateRange) {
    state.allOrdersDateRanges = []
  } else {
    const { startDate, endDate } = dateRange
    const dateRanges = state.allOrdersDateRanges
    dateRanges.push({ startDate, endDate })

    state.allOrdersDateRanges = minimizeRanges(dateRanges)
  }
}

export function setNotification(state, payload) {
  state.notification = payload
}

export function setSoundOn(state, payload) {
  state.soundOn = !!payload
}

export function setUsers(state, payload) {
  state.allUsers = payload
  let users = payload
  if (state.currentUserAccess?.restaurantIds?.length && users) {
    const filteredUsers = {}
    for (var [userId, user] of Object.entries(users)) {
      if (
        state.currentUserAccess.restaurantIds.filter(accessRestaurant =>
          user.restaurantIds.includes(accessRestaurant)
        ).length > 0
      ) {
        filteredUsers[userId] = user
      }
    }
    users = filteredUsers
  }
  state.users = users
}

export function setTaxes(state, payload) {
  let taxes = payload
  if (state.currentUserAccess?.restaurantIds?.length) {
    taxes = payload.filter(tax => {
      return (
        state.currentUserAccess.restaurantIds.filter(accessRestaurant =>
          tax.restaurantIds.includes(accessRestaurant)
        ).length > 0
      )
    })
  }
  state.taxes = taxes.sort(function (a, b) {
    var textA = a.name?.toUpperCase()
    var textB = b.name?.toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })
}

export function addTax(state, payload) {
  let taxes = [...state.taxes]
  taxes.forEach(function (tax, i) {
    if (tax.id === payload.taxID) {
      taxes[i] = payload
    } else {
      taxes.push(payload)
    }
  })
  state.taxes = [...new Set(taxes)]
}

export function addOrderToPrint(state, orderId) {
  state.ordersToPrint.push(orderId)
}

export function removeOrderFromPrint(state, orderId) {
  // console.log(
  //   "removing order from print",
  //   orderId,
  //   state.ordersToPrint,
  //   state.ordersToPrint.filter(order => order.id !== orderId)
  // )
  state.ordersToPrint = state.ordersToPrint.filter(order => order.id !== orderId)
}

export function setModifierLists(state, payload) {
  state.modifierLists = payload
}

export function addModifier(state, payload) {
  let modifiers = [...state.modifierLists]
  modifiers.forEach(function (modifier, i) {
    if (modifier.id === payload.id) {
      modifiers[i] = payload
    } else {
      modifiers.push(payload)
    }
  })
}

export function setModifierItems(state, payload) {
  state.modifierItems = payload
}

export function setCoupons(state, payload) {
  state.coupons = payload
}

export function addCoupon(state, payload) {
  let coupons = [...state.coupons]
  coupons.forEach(function (coupon, i) {
    if (coupon.couponID === payload.couponID) {
      coupons[i] = payload
    } else {
      coupons.push(payload)
    }
  })
  state.coupons = [...new Set(coupons)]
}

export function setRefunds(state, payload) {
  state.refunds = payload
}

export function setOrders(state, payload) {
  let orders = payload
  if (state.currentUserAccess?.restaurantIds?.length) {
    orders = orders.filter(
      order =>
        order.restaurantIds?.filter(orderRestaurantId =>
          state.currentUserAccess.restaurantIds.includes(orderRestaurantId)
        ).length > 0 ||
        order.restaurants?.filter(orderRestaurant =>
          state.currentUserAccess.restaurantIds.includes(orderRestaurant.id)
        ).length > 0
    )
  }
  orders = orders.map(order => {
    const fixedOrder = { ...order }
    fixedOrder.restaurants = Object.values(order.restaurants || {})
    if (state.currentUserAccess?.restaurantIds?.length) {
      fixedOrder.restaurants = fixedOrder.restaurants?.filter(orderRestaurant =>
        state.currentUserAccess.restaurantIds.includes(orderRestaurant.id)
      )
    }
    return fixedOrder
  })
  state.orders = orders.sort(function (a, b) {
    return a.pickupDate?.toDate() - b.pickupDate?.toDate()
  })
}

export function setPaidOrderIds(state, payload) {
  state.paidOrderIds = payload
}

export function setLoyaltyRewards(state, payload) {
  state.loyaltyRewards = payload
}

export function setPromoImages(state, payload) {
  state.promoImages = payload
}

export function addPromoImage(state, payload) {
  let promoImages = [...state.promoImages]
  promoImages.forEach(function (promoImage, i) {
    if (promoImage.id === payload.id) {
      promoImages[i] = payload
    } else {
      promoImages.push(payload)
    }
  })
  state.promoImages = [...new Set(promoImages)]
}

export function setCustomers(state, payload) {
  state.customers = payload
}

export function setDevices(state, payload) {
  state.devices = payload
}

export function setSuppliers(state, payload) {
  state.suppliers = payload
}

export function addSupplier(state, payload) {
  state.suppliers.push(payload)
}

export function setUnits(state, payload) {
  state.units = payload
}

export function addUnit(state, payload) {
  let units = [...state.units]
  units.forEach(function (unit, i) {
    if (unit.id === payload.id) {
      units[i] = payload
    } else {
      units.push(payload)
    }
  })
  state.units = [...new Set(units)]
}

export function setSettings(state, payload) {
  state.settings = payload
}

export function setSettingsByVisibility(state, payload) {
  state.settingsByVisibility = payload
}

export function setChannels(state, payload) {
  let channels = payload

  if (state.currentUserAccess?.restaurantIds?.length) {
    channels = payload.filter(channel => {
      if (channel.restaurantIds != undefined) {
        return (
          state.currentUserAccess.restaurantIds.filter(accessRestaurant =>
            channel.restaurantIds.includes(accessRestaurant)
          ).length > 0
        )
      }
      if (channel.restaurantData != undefined) {
        return state.currentUserAccess.restaurantIds.some(accessRestaurant =>
          channel.restaurantData?.some(r => r.restaurantId === accessRestaurant)
        )
      }
      if (channel.restaurantId != undefined) {
        return (
          state.currentUserAccess.restaurantIds.filter(
            accessRestaurant => channel.restaurantId == accessRestaurant
          ).length > 0
        )
      }
      return true
    })
  }
  state.channels = channels
}

export function setReportDateRange(state, payload) {
  state.reportDateRange = payload
}

export function setDateRangeManually(state, payload) {
  if (state.reportDateRange.length < 2) {
    state.reportDateRange.push(payload)
  }
}

export function setPurchaseOrders(state, orders) {
  state.purchaseOrders = orders
}

export function addPurchaseOrder(state, payload) {
  let purchaseOrders = [...state.purchaseOrders]
  purchaseOrders.forEach(function (order, i) {
    if (order.id === payload.id) {
      purchaseOrders[i] = payload
    } else {
      purchaseOrders.push(payload)
    }
  })
  state.purchaseOrders = [...new Set(purchaseOrders)]
}

export function setCuisines(state, payload) {
  state.cuisines = payload
}

export function setFilter(state, payload) {
  const [page, key, value] = payload
  state.filters[page] = state.filters[page] || {}
  state.filters[page][key] = value
}
